import React from 'react';
import { Card, Button, Row, Col, Container, Stack } from 'react-bootstrap';
import { MdEvent } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const EventCard = ({ event }) => {

    const date = new Date(event.date);
    const options = { weekday: 'short', month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(`/myevents/${event.id}`);
    };
    return (
        <Container>
            <Card className='col-md-12'>
                <Card.Img variant="top" src={event.thumbnail} />
                <Card.Body>
                    <Stack gap={3}>
                        <Row>
                            <Col></Col>
                            <Col>
                                <Card.Title>{event.name}</Card.Title>
                            </Col>
                            <Col>
                                <Card.Text><MdEvent /> {formattedDate}</Card.Text>
                            </Col>

                        </Row>
                        <Row>
                            <Card.Text>{event.description}</Card.Text>
                        </Row>
                        <Row>
                            <Button variant="primary" onClick={handleButtonClick}>Go to Event</Button>
                        </Row>
                    </Stack>


                </Card.Body>
            </Card>
        </Container>

    );
};

export default EventCard;