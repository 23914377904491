import React  from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
// import 'dotenv/config'

function Homepage() {
  // Access the environment variable
  // require('dotenv').config({ debug: true })
  console.log(process.env)
  const myEnvVar = process.env.REACT_APP_RSVP_API_URL;

  return (
    <Container>
      <Row>
        <Col>
          <h1>Welcome to My App</h1>
          <p>Here's some information about what the app does.</p>
          <p>Environment Variable: {myEnvVar}</p>
          <Button variant="primary">Get Started</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Homepage;