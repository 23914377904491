import React, { useState, useEffect, useContext } from "react";
import { CSSTransition } from 'react-transition-group';
import { Navbar, Container, Nav, Button, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from '../assets/img/westbridestory.webp';
import { useAuth } from "../auth/AuthProvider";
import { useAdmin } from "../auth/AdminProvider";

const MyNavbar = () => {
  const [navbarHeight, setNavbarHeight] = useState(20);
  const [isTitleSmall, setIsTitleSmall] = useState(false);
  const { user, logout } = useAuth();
  const { isAdmin } = useAdmin();


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setNavbarHeight(5);
        setIsTitleSmall(true);
      } else {
        setNavbarHeight(20);
        setIsTitleSmall(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar style={{ height: `${navbarHeight}%` }} bg="dark" variant="dark">
      <Container>
        <img
          src={logo}
          width="100"
          height="100"
          className="d-inline-block align-top"
          alt="West Bride Story Logo"
        />

        <Navbar.Brand>

          <h1 className={isTitleSmall ? "title-small" : "title-large"}>
            {isAdmin ? "ADMIN MANAGER" : "RSVP Manager"}
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {user ? (
              <Dropdown>
                <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                  {user.first_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/myevents">My Events</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                  {isAdmin && <Dropdown.Item as={Link} to="/admin">Admin Console</Dropdown.Item>}
                  <Dropdown.Item onClick={logout} as={Link} to="/">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Link to="/login">
                <Button variant="outline-light">Login</Button>
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

  );
};

export default MyNavbar;