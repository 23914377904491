import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { Row, Col, CardGroup } from 'react-bootstrap';
import { useAuth } from '../auth/AuthProvider';
import EventCard from '../components/EventCard';

function MyEventsPage() {
  const [events, setEvents] = useState([]);
  let navigate = useNavigate();
  const {user} = useAuth();

  useEffect(() => {
    // Make an asynchronous request to your API to fetch the events owned by the user

    async function fetchEvents() {

      try {
        const response = await api.get("/event");
        if (response.status !== 200) {
            console.log('Error being thrown :(')
            throw new Error(response.data);
        }

        const data = await response.data;
        setEvents(data);

      } catch (error) {
        console.error(error);
      }
    }

    fetchEvents();
  }, []);

  return (
    <div>
      <CardGroup>
        <Row xs={1} md={1} lg={1}className="justify-content-md-center">
        <h1 >My Events</h1>
        </Row>
      
      <Row xs={1} md={1} lg={1}className="justify-content-md-center">
        {events.map((event) => (
          <Col key={event.id}>
            <EventCard event={event} />
          </Col>
        ))}
      </Row>
      </CardGroup>
      
    </div>
  );
}

export default MyEventsPage;