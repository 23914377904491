import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_RSVP_API_URL || "http://localhost:5002/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  xsrfHeaderName: "X-CSRF-TOKEN",
  xsrfCookieName: "csrf_access_token"
});

export default api;