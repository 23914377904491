import React, { useState, useEffect, createContext, useContext } from "react";
// import axios from "axios";
import GuestTable from "../components/GuestTable";
import EventSummary from "../components/EventSummary";
import Container from 'react-bootstrap/Container';
import { Row } from "react-bootstrap";
import api from "../api";
import { useParams } from "react-router-dom";

export const EventContext = createContext();

function EventManager() {
    const [summary, setSummary] = useState(null);
    const [guests, setGuests] = useState(null);
    const [error, setError] = useState(null);

    let {eventId} = useParams()
    const id = eventId;
    console.log('the id', id)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/event/" + eventId);
                if (response.status !== 200) {
                    console.log('Error being thrown :(')
                    throw new Error(response.data);
                }

                const data = await response.data;
                setSummary(data.summary);
                setGuests(data.guests);
            } catch (error) {
                console.log(error)
                setError(true);
            }
        };
        fetchData();
    }, [eventId]);

    if (!summary && !error) return (<Container>loading...</Container>)
    if (error) return (<Container>This event doesn't exist</Container>)
    return (
        <EventContext.Provider value={eventId}>
            <Container fluid>
                <Row>
                    <Container>
                        <EventSummary summary={summary} />
                    </Container>

                </Row>
                <Row>
                    <Container>
                        <GuestTable guests={guests} summary={summary} />
                    </Container>
                </Row>
                {/* <Row>
                    <Container>
                        <GuestList guests={guests} />
                    </Container>
                </Row> */}

            </Container>
        </EventContext.Provider>

    );
}

export default EventManager;