import React  from 'react';
import { createContext, useContext, useEffect, useState } from "react"
import api from "../api";
import { useNavigate } from "react-router-dom";

// Create context to make a context reference.
const authContext = createContext()


// Provider component that warp your app and make auth object
// available to any child component that calls useAuth() 
export function AuthProvider({ children }) {
  let navigate = useNavigate();
  const [user, setUser] = useState(() => {
    // Try to load the user from localStorage
    const userFromStorage = localStorage.getItem("user");
    return userFromStorage ? JSON.parse(userFromStorage) : null;
  });

  useEffect(() => {
    async function checkAuth() {
      console.log('Checking Auth')
      try {
        const response = await api.get("/auth/whoami");
        if (response.data.user) {
          setUser(response.data.user);
          // Save the user to localStorage
          localStorage.setItem("user", JSON.stringify(response.data.user));
        }
      } catch (error) {
        if (error.response.status === 401){
          setUser(null);
          // Remove the user from localStorage
          localStorage.removeItem("user");
          navigate("/login")
        } else {
          console.log(error)
        }

      }
    }


    checkAuth();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.post("/auth/login", { email, password });
      if (response.data.login === true) {
        setUser(response.data.user);
        // Save the user to localStorage
        localStorage.setItem("user", JSON.stringify(response.data.user));
      } else {
        setUser(null);
      }
      return response.data.user;
    } catch (error) {
      throw error.response.data.msg; // Throw the error message from the server
    }
  };

  const register = async (firstname, lastname, email, password) => {
    const response = await api.post("/auth/register", { firstname, lastname, email, password });
    if (response.data.err) {
      throw response.data.err
    }
    if (response.data.loggedIn === true) {
      setUser(response.data.user);
      // Save the user to localStorage
      localStorage.setItem("user", JSON.stringify(response.data.user));
    } else {
      setUser(null);
    }
    return response.data.user;
  }

  const logout = async () => {
    const response = await api.post("/auth/logout");
    if (response.data.err) {
      throw response.data.err
    }
    if (response.data.loggedIn === true) {
      console.log("Something went wrong!");
    }
    setUser(null);
    // Remove the user from localStorage
    localStorage.removeItem("user");
    navigate("/")
  }

  return (
    <authContext.Provider value={{ user, login, register, logout }}>
      {children}
    </authContext.Provider>
  )
}


// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}