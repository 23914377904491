import React, { useState } from "react";
import { Form, Button, Row, Col, Alert, FloatingLabel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from './AuthProvider';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const auth = useAuth()
  const login = auth.login

  const navigate = useNavigate(); // <-- get history from hook

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await login(email, password);
      navigate ("/myevents");
    } catch (error) {
      setError(error); // Set the error state with the error message from the server
    }
  };

  return (
    <>
      <div>
        <Row className="justify-content-md-center">
          <Col md={6} className="text-center">
            <Form onSubmit={handleSubmit}>
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3">
                <Form.Control type="email" placeholder="name@example.com" onChange={handleEmailChange} />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control type="password" placeholder="Password" onChange={handlePasswordChange} />
              </FloatingLabel>
              {error && <Alert variant="danger">{error}</Alert>}
              <Button variant="primary" type="submit">Login</Button>
            </Form>
          </Col>
        </Row>
        <Row className="justify-content-md-center p-2">
          <Col md={6} className="text-center">
            <Link to="/login/forgot">
              <Button variant="secondary">Forgotten Password?</Button>
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-md-center p-2">
          <Col md={6} className="text-center">
            <Link to="/signup">
              <Button variant="secondary">Not got an account?</Button>
            </Link>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default Login;