import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { Container, Row, Col, Table } from 'react-bootstrap';

const Profile = () => {
    const [profileData, setProfileData] = useState({});
    const navigate = useNavigate(); // <-- get history from hook

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await api.get("/auth/whoami");
                console.log(response.data)
                if (response.status !== 200) {
                    console.log('Error being thrown :(')
                    throw new Error('Not authenticated');
                }

                const data = await response.data;
                setProfileData(data);
            } catch (error) {
                console.log(error)
                navigate("/login")
            }
        };

        fetchProfileData();
    }, [navigate]);

    if (!Object.keys(profileData).length) {
        return (
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                    <div className="jumbotron">
                            <h3>Loading...</h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container fluid>
            <Row>
                <Col md={6} className="offset-md-3">
                <div className="jumbotron">
                        <h3 className="text-center">Profile</h3>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>First Name</td>
                                    <td>{profileData.first_name}</td>
                                </tr>
                                <tr>
                                    <td>Last Name</td>
                                    <td>{profileData.last_name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{profileData.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>{profileData.phone_number}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;


