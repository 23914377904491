
import React, { useEffect } from 'react';

const CloudflareAnalytics = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.defer = true;
      script.src = 'https://static.cloudflareinsights.com/beacon.min.js';
      script.dataset.cfBeacon = JSON.stringify({ token: process.env.REACT_APP_CLOUDFLARE_TOKEN });
      document.head.appendChild(script);

      return () => {
        // Cleanup the script when the component unmounts
        document.head.removeChild(script);
      };
    }
  }, []);

  return null;
};

export default CloudflareAnalytics;