import './App.css';
import React, { useState } from "react";
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import Login from './auth/Login';
import Signup from './auth/Signup';
import EventManager from './protected/EventManager';
import Homepage from './Home';
import ForgotPassword from './auth/ForgotPassword';
import MyNavBar from './components/MyNavBar'
import axios from 'axios';
import Profile from './protected/Profile';
import {useAuth } from './auth/AuthProvider';
import MyEventsPage from './protected/MyEvents';
import { useAdmin } from './auth/AdminProvider';
import AdminConsole from './protected/admin/AdminConsole';


const ProtectedRoute = ({ redirectPath = '/login' }) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const AdminProtectedRoute = ({ redirectPath = '/admindenied' }) => {
  
  const { isAdmin } = useAdmin();
  console.log('Admin protected route', isAdmin)
  if (!isAdmin) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const Test = () => (
  <h1>404</h1>
)

const AdminDenied = () => (
  <h1>401</h1>
)

const App = () => {

  axios.defaults.withCredentials = true
  const { user } = useAuth();
  
  return (
    <>
      <MyNavBar />
      <div className='container'>
        {console.log(user)}
        <Routes>
          <Route path="/login" element={<Login />} exact />
          <Route path="/signup" element={<Signup />} exact />
          <Route path="/" element={<Homepage />} exact />
          <Route path="/admindenied" element={<AdminDenied />} exact />
          <Route path="/login/forgot" element={<ForgotPassword />} exact />
          <Route element={<ProtectedRoute />}>
            {/* Protected Routes here \/ */}
            <Route path="/profile" element={<Profile />} exact />
            <Route path="*" element={<Navigate to="/myevents" replace />} />
            <Route path="/myevents/" element={<MyEventsPage />} />
            <Route path="/myevents/:eventId" element={<EventManager />} />
            
            <Route element={<AdminProtectedRoute />}>
              {/* Admin Routes here \/ */}
              <Route path="/admin" element={<AdminConsole />} exact />
              {/* Admin Routes above here */}
            </Route>
          </Route>
        </Routes>
      </div>

    </>
  );
};
export default App;