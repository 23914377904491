import React  from 'react';
import { useState, useContext } from "react";
import { Modal, Button, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EventContext } from '../protected/EventManager';
import api from '../api'

function GuestModal({ show, onHide, guest }) {
  const [isAttending, setIsAttending] = useState(guest.isAttending);
  // const eventId = useContext(EventContext);
  // console.log('the id', id)
  // let {eventId} = useParams();
  const eventId = useContext(EventContext);
  const onSave = async () => {
    const response = await api.patch(`/event/${eventId}/guest/${guest.id}`,
      {
        is_attending: isAttending
      });
    if (response.ok) {
      onHide();
    } else {
      // Handle error
    }
  };

  const onDelete = async () => {
    const response = await api.delete(`/event/${eventId}/guest/${guest.id}`);
    if (response.ok) {
      onHide();
    } else {
      // Handle error
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{guest.firstName}'s RSVP</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>First Name: {guest.firstName}</p>
        <p>Last Name: {guest.lastName}</p>
        <p>Email: {guest.email}</p>
        <p>Has Responded: {guest.hasResponded ? "Yes" : "No"}</p>
        <p>
          Is Attending:{" "}
          <select
            value={isAttending}
            onChange={(event) => setIsAttending(event.target.value)}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Stack direction="horizontal" gap={3}>
          <div className="bg-light border">
            <Button variant="outline-danger" onClick={onDelete}>
              Delete Guest
            </Button>
          </div>
          <div className="ms-auto"></div>
          <div className="bg-light border">
            <Button variant="primary" onClick={onSave}>
              Save
            </Button>
          </div>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}

export default GuestModal;