import React  from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';

const AdminContext = createContext();

export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    // const [cookies] = useCookies(['access_token']);
    const { user } = useAuth();

  
    useEffect(() => {
        const admin = user?.is_administrator || false;
        setIsAdmin(admin)
    }, [user]);

    useEffect(() => {
        console.log('Updated isAdmin:', isAdmin);
      }, [isAdmin]);
  
    return (
      <AdminContext.Provider value={{ isAdmin }}>
        {children}
      </AdminContext.Provider>
    );
  };