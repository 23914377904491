import React, { useState, useRef, useContext } from 'react';
import { Button, Table, Row } from "react-bootstrap";
import GuestModal from "./GuestModal";
import AddGuestModal from './AddGuestModal';
import { FaCheck, FaQuestionCircle, FaTimes } from "react-icons/fa";
import { saveAs } from 'file-saver';
import { EventContext } from '../protected/EventManager';
import api from '../api.js'

function GuestTable({ guests, summary }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedGuest, setSelectedGuest] = useState({});
    const [showAddGuestModal, setShowAddGuestModal] = useState(false);
    const inputRef = useRef(null);
    const eventId = useContext(EventContext);

    const handleShowModal = (guest) => {
        setSelectedGuest(guest);
        setShowModal(true);
    }

    const addGuest = (guestData) => {
        // Add guest data to guests array
        const newGuests = [...guests, guestData];
        console.log(guestData)
        // Call parent component's function to update guests state
        setShowAddGuestModal(false);
    };

    const handleDownload = () => {
        const data = guests.map((guest) => [guest.firstName, guest.lastName]);
        const title = summary.title.replace(/\s/g, '_');
        const fileTitle = `${title}_RSVP.csv`;
        const file = new Blob([data.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(file, fileTitle);
    }

    const handleUpload = async (event) => {
        console.log('Upload')
        const file = event.target.files[0];
    
        if (file && file.name.endsWith(".csv")) {
            const formData = new FormData();
            formData.append("file", file);
    
            // Log formData object
            console.log("FormData:", formData);
    
            try {
                const response = await api.post(`/event/${eventId}/guest/bulk/`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    }
                  });
                const result = response.data;
                console.log(result);
            } catch (error) {
                console.log(error);
            }
        }
    }
      

    const handleFileSelect = () => {
        inputRef.current.click();
    }

    return (
        <>
        
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Has Responded</th>
                        <th>Attending</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {guests.map((guest) => (
                        <tr key={guest.id}>
                            <td>{guest.firstName}</td>
                            <td>{guest.lastName}</td>
                            <td>
                                {guest.hasResponded ? <FaCheck color="green" /> : <FaQuestionCircle color="blue" />}
                            </td>
                            <td>
                                {guest.isAttending === true ? <FaCheck color="green" /> : <FaTimes color="red" />}
                            </td>
                            <td>
                                <Button variant="primary" onClick={() => handleShowModal(guest)}>
                                    Details
                                </Button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={3}>
                            <Button onClick={() => setShowAddGuestModal(true)}>Add Guest</Button>
                        </td>
                        <td colSpan={2}>
                            <Button onClick={handleFileSelect}>Upload CSV</Button>
                            <input type="file" accept=".csv" ref={inputRef} onChange={handleUpload} style={{ display: "none" }} />
                        </td>
                    </tr>
                </tbody>
            </Table>
            <GuestModal show={showModal} onHide={() => setShowModal(false)} guest={selectedGuest} />
            {showAddGuestModal && (
                <AddGuestModal addGuest={addGuest} onHide={() => setShowAddGuestModal(false)} />
            )}
            <Button variant="primary" onClick={handleDownload}>
                Download Table
            </Button>
        </>


    );
}

export default GuestTable