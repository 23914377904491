import React from 'react';
import { useAdmin } from '../../auth/AdminProvider';

const AdminConsole = () => {
  const { isAdmin } = useAdmin();

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      {/* Your admin panel content */}
      <h1>Admin Panel</h1>
    </div>
  );
};

export default AdminConsole;