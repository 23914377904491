import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Container, Row, Col, Alert, FloatingLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import api from "../api";

const Signup = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState(''); // New state variable for phone number
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault(event);
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            await api.post('/auth/signup', {
                email,
                firstName,
                lastName,
                password,
                phone, // send phone number in the request
            });
        } catch (error) {
            setError(error.response.data.message);
        }
    };
    return (
        <div>
            <Row className="justify-content-md-center">
                <Col md={3} className="text-center">
                    <Form onSubmit={handleSubmit}>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Email address"
                            className="mb-3">
                            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingFirstName" label="First Name" className="mb-3">
                            <Form.Control type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingLastName" label="Last Name" className="mb-3">
                            <Form.Control type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPhone" label="Phone (optional)" className="mb-3">
                            <Form.Control type="text" placeholder="Phone (optional)" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                            <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </FloatingLabel>
                        <FloatingLabel controlId="floatingConfirmPassword" label="Confirm Password" className="mb-3">
                            <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </FloatingLabel>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Button variant="primary" type="submit">Sign Up</Button>
                        <Link to="/login" className="ml-3">
                            <Button variant="secondary">Already have an account?</Button>
                        </Link>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Signup;