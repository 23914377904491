import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Col, Alert, Stack } from "react-bootstrap";
import api from "../api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(event);

    try {
      // Send the user's email to the server to initiate the password reset process
      const res = await api.post("/auth/forgot-password", { email });

      if (res.data.status === 'success') {
        setSuccess("An email with instructions on how to reset your password has been sent to your email address.");
        setError("");
      } else {
        setError("An error occurred while trying to reset your password.");
      }
    } catch (err) {
      setError("An error occurred while trying to reset your password.");
    }
  };

  return (
    <Stack gap={3} className="col-md-12 mx-auto">
      <div className="d-flex align-items-center justify-content-center text-center">
        <Col md={6} className="text-center">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleEmailChange} />
            </Form.Group>
            {success && <Alert variant="success">{success}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Button variant="primary" type="submit" className="">
              Send reset link
            </Button>
          </Form>
        </Col>
      </div>
      {/* <Row className="justify-content-md-center"> */}

      {/* </Row> */}
    </Stack>


  );
};
export default ForgotPassword;

