import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

function EventSummary({ summary }) {
    console.log(summary)
    const { attendanceSummary } = summary
    return (
        <Card>
            <Card.Header>{summary.title}</Card.Header>
            <Card.Body>
                <Card.Text>
                    Date: {summary.date}
                </Card.Text>
                <Card.Text>
                    Time: {summary.start_dateTime}
                </Card.Text>
                <Card.Text>
                    Description: {summary.event_description}
                </Card.Text>
                <Card.Text>
                    Date: {summary.end_dateTime}
                </Card.Text>
            </Card.Body>
            <ListGroup className="list-group-flush">
                <ListGroup.Item>Total Invited: {attendanceSummary.totalInvited}</ListGroup.Item>
                <ListGroup.Item>Total Accepted: {summary.attendanceSummary.totalAccepted}</ListGroup.Item>
                <ListGroup.Item>Total Declined: {summary.attendanceSummary.totalDeclined}</ListGroup.Item>
                <ListGroup.Item>Total Pending Reply: {summary.attendanceSummary.totalPendingReply}</ListGroup.Item>
            </ListGroup>
        </Card>
    );
}

export default EventSummary;
